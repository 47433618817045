.verifyemail-text-container {
    padding: 8em 5em 5em 5em;
    text-align: center;
    font-family: "Helvetica";
}

.verifyemail-header {
    font-weight: bold;
    font-size: 1.8em;
}

.verifyemail-text {
    font-size: 1.2em;
    margin: 1em;
}

.verifyemail-resendemail {
    background-color: #000000;
    color: #ffffff;
    margin: 1em;
    padding: 0.7em 1.5em 0.7em 1.5em;
    font-weight: bold;
    border-radius: 0.5em;
}

.verifyemail-resendemail:hover {
    opacity: 60%;
}