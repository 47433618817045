.closet-dialog-content {
    text-align: center;
    font-family: MarkPro;
}

.closet-upload-disabled {
    display: none !important;
}

.preview-image-container {
    display: flex;
    flex-direction: column;
}

.preview-image {
    width: 40em;
    height: 15em;
    object-fit: contain;
}

.preview-image-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s ease;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 1em;
    opacity: 0;
    position: absolute;
    font-family: "MarkPro";
    color: white;
    font-size: 1em;
    top: 33%;
    left: 50%;
    width: 92%;
    height: 47%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.preview-image-overlay:hover {
    opacity: 1;
    cursor: pointer;
}

.closet-upload-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3em 1.5em;
    font-family: "MarkPro";
    transition: all 0.3s ease-in-out;
    border: 0.1em black dashed;
}

.closet-upload-input:hover {
    cursor: pointer;
    transform: scale(101%);
}

.closet-input-header {
    font-weight: bold;
    font-size: 1.2em;
}

.closet-input-caption {
    font-weight: bold;
    font-size: 0.8em;
}

.closet-dialog-user-interaction {
    display: flex;
    flex-direction: column;
}

.closet-dialog-label {
    font-weight: bold;
    margin: 1em 0 0.5em 0;
}

.closet-dialog-divider-container {
    display: flex;
    gap: 0.5em;
}

.closet-dialog-divider {
    border: 0.1em solid lightgray;
    border-radius: 1em;
    margin-top: 1em;
    width: 13em;
}


.closet-input-name {
    border-radius: 0.2em;
}

.closet-input-name::placeholder {
    color: black;
}

.closet-upload-button {
    margin: 1em;
    padding: 0.5em 2em 0.5em 2em;
    background-color: #000000;
    color: #ffffff;
    border-radius: 0.5em;
}

.closet-delete-categories {
    margin: 0em 0 0.5em 0;
}

.closet-delete-category {
    font-weight: bold;
    margin: 1.5em 1em 0.3em 0.3em;
    font-size: 1.2em;
}

.closet-merge-button {
    margin: 1em;
    padding: 1em 8em 1em 8em;
    color: #ffffff;
    border-radius: 0.5em;
}

.closet-delete-button {
    margin: 1em 0.3em 1em 0.3em;
    color: #ffffff;
    border-radius: 0.5em;
    padding: 0.8em 2.3em 0.8em 2.3em;
}

.closet-delete-button {
    background-color: black;
}

.closet-merge-button {
    background-color: #a9a9a9;
}

.closet-merge-button:hover {
    background-color: #787878;
}

.closet-dialog-outfit-name {
    display: block;
    font-weight: bold;
    font-size: 1.2em;
}

.item-modal-buttons {
    display: flex;
    gap: 0;
}

.item-modal-content {
    display: flex;
    gap: 5.5vw;
}

.outfit-image-container {
    display: flex;
    gap: 1em;
}

.outfit-clothes-img {
    width: 15em;
}