.navbar-container {
    display: flex;
    justify-content: space-between;
    font-family: "MarkPro";
    background-color: #ffffff;
    width: 100%;
    margin-top: 0.5em;
    padding: 0.5em 2em 0.5em 2em;
    font-size: 1.5em;
}

.navbar-signin {
    position: relative;
    background-image: linear-gradient(to right, #f2e9ff, #a4d3ff);
    padding: 0.3em 0.8em 0.3em 0.8em;
    border-radius: 0.5em;
    font-size: 0.8em;
    font-family: 'MarkPro';
    font-weight: 600;
    transition: all 0.4s;
}

.navbar-signin::after {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    content: "Sign in";
    background-image: linear-gradient(to right, #e66465, #9198e5);
    border-radius: 0.5em;
    transition: all 0.4s ease-in-out;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
}

.navbar-signin:hover::after {
    opacity: 1;
}