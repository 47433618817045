@font-face {
    font-family: "FuturaMaxi";
    src: url('./Assets/Fonts/FuturaMaxi-Regular.otf');
}

@font-face {
    font-family: "MarkPro";
    src: url('./Assets/Fonts/Mark-Pro.ttf');
}

@font-face {
    font-family: "ApercuReg";
    src: url('./Assets/Fonts/apercu-regular-pro.ttf');
}

@font-face {
    font-family: "SauvageAnt";
    src: url('./Assets/Fonts/sauvage-antique.ttf');
}

@font-face {
    font-family: "Linotype";
    src: url('./Assets/Fonts/Linotype-Didot-Bold.otf');
}   

@font-face {
    font-family: "Inter";
    src: url('./Assets/Fonts/Inter-Regular.ttf');
}  

@font-face {
    font-family: "Helvetica";
    src: url('./Assets/Fonts/HelveticaNowMTTextRegular.woff');
}  